:root {
  --light-grey: #eee;
  --satys-dark-accent: #3f040c;
  --satys-accent: #ba2e6d;
  --satys-light-accent: #fbf8fd;
  --satys-dark-blue: #001b44;
  --satys-purple: #833ab4;
  --satys-red: #fd1d1d;
  --satys-light-purple: #c980e1;
  --satys-light-red: #eb6e6e;
  --satys-light-blue: #add1eb;
  --satys-light-green: #33d9b2;
  --satys-graph-red: #ae7f7f;
  --satys-graph-green: #84ad7f;
  --satys-graph-blue: #93d9f1;
  --satys-graph-neutral: #dedede;
  --satys-text-color: #1b1249;
  --satys-text-color-light: #8b97a9;
  --satys-bg-color: #f9f9fb;
  --header-height: 0px;
  --animation-duration: 500;
  --animation-duration-long: 1000;
}

.tableview_994d5a table {
  width: 100%;
}

.tableview_994d5a table tr {
  min-height: 42px;
  border-bottom: 1px solid #d3d3d3;
  gap: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
}

.tableview_994d5a table tr:last-child {
  border-bottom: none;
}

.tableview_994d5a table tr:first-child:after {
  opacity: 0;
}

.tableview_994d5a table tr * {
  flex: 1;
}

.tableview_994d5a table tr td, .tableview_994d5a table tr th {
  align-items: center;
  display: flex;
  position: relative;
}

.tableview_994d5a table tbody tr:hover {
  color: #fff;
  cursor: pointer;
  background-color: #ba2e6d;
}

.tableview_994d5a .table-active-indicator {
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  flex: 0 0 10px;
  margin: auto;
  display: block;
  position: relative;
  transform: scale(1);
}

.tableview_994d5a .table-active-indicator.pulse {
  background: #33d9b2;
  transition: all .2s;
  animation: pulse-green 2s infinite;
  box-shadow: 0 0 #33d9b2;
}

@keyframes pulse-green_994d5a {
  0% {
    transform: scale(.95);
    box-shadow: 0 0 #33d9b2b3;
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #33d9b200;
  }

  100% {
    transform: scale(.95);
    box-shadow: 0 0 #33d9b200;
  }
}

/*# sourceMappingURL=index.485cc20f.css.map */
